@mixin enx-button {
  .mdc-button.enx-button {
    // FILLED
    // filled, green
    &--filled-green {
      @include enx-button--filled-green;
    }

    // filled, red
    &--filled-red {
      @include enx-button--filled-red;
    }

    // OUTLINED
    // outlined, green, border 1px
    &--outlined-green-1 {
      @include enx-button--outlined-green;
      @include enx-button--outlined-thin;
    }

    // outlined, green, border 2px
    &--outlined-green-2 {
      @include enx-button--outlined-green;
      @include enx-button--outlined-thick;
    }

    // outlined, red, border 2px
    &--outlined-red-2 {
      @include enx-button--outlined-red;
      @include enx-button--outlined-thick;
    }

    // outlined, black, border 2px
    &--outlined-gray-2 {
      @include enx-button--outlined-gray;
      @include enx-button--outlined-thick;
    }

    &--outlined-gray-hover-red {
      @include enx-button--outlined-gray-hover-red;
      @include enx-button--outlined-thick;
    }

    // outlined, black, dashed, border 1px
    &--outlined-gray-dashed-1 {
      @include enx-button--outlined-gray;
      @include enx-button--outlined-dashed;
      @include enx-button--outlined-thin;
    }

    // SIZE
    // mobile 32px, desktop 32px, text 16px
    &--size-32-32-16 {
      @include enx-button--mobile-sm;
      @include enx-button--desktop-sm;
      @include enx-button--text-lg;
    }

    // mobile 48px, desktop 32px, text 16px
    &--size-48-32-16 {
      @include enx-button--mobile-lg;
      @include enx-button--desktop-sm;
      @include enx-button--text-lg;
    }

    // mobile 48px, desktop 48px, text 16px
    &--size-48-48-16 {
      @include enx-button--mobile-lg;
      @include enx-button--desktop-lg;
      @include enx-button--text-lg;
    }

    // mobile 32px, desktop 32px, text 14px
    &--size-32-32-14 {
      @include enx-button--mobile-sm;
      @include enx-button--desktop-sm;
      @include enx-button--text-md;
    }

    // mobile 32px, desktop 32px, text 14px regular
    &--size-32-32-14-regular {
      @include enx-button--mobile-sm;
      @include enx-button--desktop-sm;
      @include enx-button--text-md-regular;
    }

    // mobile 44px, desktop 32px, text 14px
    &--size-44-32-14 {
      @include enx-button--mobile-md;
      @include enx-button--desktop-sm;
      @include enx-button--text-md;
    }

    // mobile 48px, desktop 32px, text 14px
    &--size-48-32-14 {
      @include enx-button--mobile-lg;
      @include enx-button--desktop-sm;
      @include enx-button--text-md;
    }

    // mobile 48px, desktop 48px, text 14px
    &--size-48-48-14 {
      @include enx-button--mobile-lg;
      @include enx-button--desktop-lg;
      @include enx-button--text-md;
    }

    // mobile 32px, desktop 32px, text 12px
    &--size-32-32-12 {
      @include enx-button--mobile-sm;
      @include enx-button--desktop-sm;
      @include enx-button--text-sm;
    }

    // OTHER
    &--with-icon {
      --mat-outlined-button-icon-spacing: 0.125rem;
      --mat-outlined-button-icon-offset: -8px;

      .mdc-button__label {
        @apply mt-0.5;
      }

      .mat-icon {
        @apply w-4 h-4;
      }
    }
  }
}

@mixin enx-button--mobile-sm {
  @apply h-8 px-4 rounded-lg;
}

@mixin enx-button--mobile-md {
  @apply h-11 px-6 rounded-lg;
}

@mixin enx-button--mobile-lg {
  @apply h-12 px-6 rounded-lg;
}

@mixin enx-button--desktop-sm {
  @apply md:h-8 md:px-4 md:rounded-lg;
}

@mixin enx-button--desktop-md {
  @apply md:h-11 md:px-6 md:rounded-lg;
}

@mixin enx-button--desktop-lg {
  @apply md:h-12 md:px-6 md:rounded-lg;
}

@mixin enx-button--text-sm {
  .mdc-button__label {
    @apply text-caption-semibold;
  }
}

@mixin enx-button--text-md {
  .mdc-button__label {
    @apply text-body-2-semibold;
  }
}

@mixin enx-button--text-md-regular {
  .mdc-button__label {
    @apply text-body-2-regular;
  }
}

@mixin enx-button--text-lg {
  .mdc-button__label {
    @apply text-body-semibold;
  }
}

@mixin enx-button--outlined-thick {
  &.mat-mdc-outlined-button {
    --mdc-outlined-button-outline-width: 2px;
  }
}

@mixin enx-button--outlined-thin {
  &.mat-mdc-outlined-button {
    --mdc-outlined-button-outline-width: 1px;
  }
}

@mixin enx-button--outlined-dashed {
  &.mat-mdc-outlined-button {
    @apply border-dashed;
  }
}

@mixin enx-button--outlined-green {
  &.mat-primary.mat-mdc-outlined-button {
    --mat-outlined-button-ripple-color: theme(colors.black-transparent.5);
    --mdc-outlined-button-outline-color: theme(colors.green.DEFAULT);

    &:not(:disabled) {
      &:hover {
        @apply border-green-dark text-green-dark;
      }
    }
  }
}

@mixin enx-button--outlined-red {
  &.mat-warn.mat-mdc-outlined-button {
    --mat-outlined-button-ripple-color: theme(colors.black-transparent.5);
    --mdc-outlined-button-outline-color: theme(colors.red.DEFAULT);

    &:not(:disabled) {
      &:hover {
        @apply border-red-dark text-red-dark;
      }
    }
  }
}

@mixin enx-button--outlined-gray {
  &.mat-mdc-outlined-button {
    --mdc-outlined-button-label-text-color: theme(colors.gray-dark);
    --mdc-outlined-button-outline-color: theme(colors.gray-dark);
    --mdc-outlined-button-disabled-outline-color: theme(colors.gray-light);
    --mdc-outlined-button-disabled-label-text-color: theme(colors.gray-light);
    --mat-outlined-button-ripple-color: theme(colors.black-transparent.5);

    &:not(:disabled) {
      &:hover {
        @apply border-black text-black;
      }
    }
  }
}

@mixin enx-button--outlined-gray-hover-red {
  &.mat-mdc-outlined-button {
    --mdc-outlined-button-label-text-color: theme(colors.gray-dark);
    --mdc-outlined-button-outline-color: theme(colors.gray-dark);
    --mdc-outlined-button-disabled-outline-color: theme(colors.gray-light);
    --mdc-outlined-button-disabled-label-text-color: theme(colors.gray-light);
    --mat-outlined-button-ripple-color: theme(colors.black-transparent.5);

    &:not(:disabled) {
      &:hover {
        @apply border-red text-white bg-red;
      }
    }
  }
}

@mixin enx-button--filled-green {
  &.mat-primary.mat-mdc-unelevated-button {
    --mat-filled-button-ripple-color: theme(colors.black-transparent.10);
    --mdc-filled-button-disabled-container-color: theme(colors.green.200);
    --mdc-filled-button-disabled-label-text-color: theme(colors.white);

    &:not(:disabled) {
      &:hover {
        @apply bg-green-dark;
      }
    }
  }
}

@mixin enx-button--filled-red {
  &.mat-warn.mat-mdc-unelevated-button {
    --mat-filled-button-ripple-color: theme(colors.black-transparent.10);

    &:not(:disabled) {
      &:hover {
        @apply bg-red-dark;
      }
    }
  }
}
