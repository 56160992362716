@use 'libs/shared/styles/styles.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-family: 'Noto Sans', sans-serif;
  @apply text-black text-[100%] font-normal;
}
