@mixin enx-icon-button {
  .enx-icon-button.mat-mdc-icon-button.mat-mdc-button-base {
    --mat-icon-button-ripple-color: theme(colors.black-transparent.3);
    --mat-icon-button-focus-state-layer-opacity: 0.07;
    --mat-icon-button-pressed-state-layer-opacity: 0.07;

    @apply h-10 w-10 p-1;

    .mat-icon {
      @apply translate-y-[2px];
    }

    .mat-mdc-button-touch-target {
      @apply h-10 w-10;
    }

    &.mat-primary {
      --mat-icon-color: theme(colors.green.DEFAULT);

      &:hover {
        --mat-icon-color: theme(colors.green-dark);
      }
    }

    &.mat-warn {
      --mat-icon-color: theme(colors.red.DEFAULT);
    }
  }
}

@mixin enx-form-field {
  .mat-mdc-form-field.enx-form-field {
    --mdc-outlined-text-field-outline-color: theme(colors.gray-light);
    --mdc-outlined-text-field-focus-outline-width: 1px;
    --mdc-outlined-text-field-hover-outline-color: theme(colors.gray-light);
    --mdc-outlined-text-field-input-text-color: theme(colors.black);
    --mdc-outlined-text-field-label-text-color: theme(colors.gray-light);

    &:not(.enx-form-field--readonly) {
      .mat-mdc-text-field-wrapper {
        &.mdc-text-field--outlined {
          --mat-form-field-container-height: 3rem;
          --mat-form-field-container-vertical-padding: 0.75rem;
          --mdc-outlined-text-field-container-shape: 0.5rem;
          --mdc-outlined-text-field-outline-color: theme(colors.gray-light);
          --mdc-outlined-text-field-input-text-placeholder-color: theme(colors.gray-light);
          --mdc-outlined-text-field-focus-outline-color: theme(colors.green.DEFAULT);
          --mdc-outlined-text-field-disabled-outline-color: theme(colors.gray);
          --mdc-outlined-text-field-disabled-input-text-color: theme(colors.gray);
          --mdc-outlined-text-field-disabled-label-text-color: theme(colors.gray);
          --mat-form-field-disabled-input-text-placeholder-color: theme(colors.gray-light);

          .mdc-notched-outline {
            &__leading,
            &__trailing {
              @apply bg-white;
            }
            &__notch {
              @apply bg-white border-r-0;
            }
          }

          .mat-mdc-form-field-text-prefix {
            @apply z-10 text-body-regular text-black pr-1;
          }

          .mat-mdc-form-field-infix {
            @apply z-10;

            .mdc-text-field__input {
              @apply text-body-regular;

              &:disabled {
                @apply cursor-not-allowed;
              }
            }
          }

          &.mdc-text-field--disabled {
            .mdc-notched-outline {
              &__leading,
              &__trailing,
              &__notch {
                @apply opacity-50;
              }
            }
          }
        }
      }
    }

    .mat-mdc-form-field-subscript-wrapper {
      @apply h-4 text-caption-regular;

      &::before {
        @apply h-3;
      }

      .mat-mdc-form-field-error-wrapper {
        @apply p-0;

        .mat-mdc-form-field-error {
          &::before {
            @apply h-3;
          }
        }
      }
    }

    &--readonly {
      .mat-mdc-text-field-wrapper {
        &.mdc-text-field--outlined {
          --mat-form-field-container-height: 2.25rem;
          --mdc-outlined-text-field-container-shape: 0;
          --mdc-outlined-text-field-input-text-placeholder-color: theme(colors.transparent);
          --mdc-outlined-text-field-disabled-input-text-color: theme(colors.black);
          --mdc-outlined-text-field-focus-outline-color: theme(colors.gray-light);
          --mdc-outlined-text-field-disabled-outline-color: theme(colors.gray-light);
          --mat-form-field-disabled-input-text-placeholder-color: theme(colors.transparent);

          .mdc-notched-outline {
            &__leading,
            &__trailing {
              @apply bg-transparent;
            }

            &__leading {
              @apply border-l-0;
            }

            &__trailing {
              @apply border-t-0 border-r-0;
            }
            &__notch {
              @apply bg-transparent border-r-0;
            }
          }

          .mat-mdc-form-field-text-prefix {
            @apply text-body-regular pr-1 md:text-body-2-regular;
          }

          .mat-mdc-form-field-infix {
            @apply pt-0;

            .mdc-text-field__input {
              @apply text-body-regular md:text-body-2-regular;
            }
          }
        }
      }
    }

    &--no-wrapper {
      .mat-mdc-form-field-subscript-wrapper {
        @apply hidden;
      }
    }

    &--darker-label {
      --mdc-outlined-text-field-label-text-color: theme(colors.gray);
    }
  }
}

@mixin enx-radio-group {
  .mat-mdc-radio-group.enx-radio-group {
    @apply flex gap-4;

    .mat-mdc-radio-button.mat-primary {
      --mdc-radio-unselected-icon-color: theme(colors.gray-light);
      --mdc-radio-unselected-hover-icon-color: theme(colors.gray-light);
      --mat-radio-label-text-color: theme(colors.black);
      --mdc-radio-state-layer-size: 1.5rem;

      .mdc-radio {
        @apply h-6 w-6 p-0;

        .mat-mdc-radio-touch-target {
          @apply h-6 w-6;
        }

        .mdc-radio__background {
          @apply h-6 w-6;

          .mdc-radio__outer-circle {
            @apply border;
          }

          .mdc-radio__inner-circle {
            @apply border-[0.75rem];
          }
        }
      }

      .mdc-label {
        @apply text-body-regular pl-2;
      }

      .mdc-radio__native-control:checked + .mdc-radio__background .mdc-radio__inner-circle {
        @apply scale-[0.42];
      }
    }
  }
}

@mixin enx-sidenav {
  .mat-sidenav-container.enx-sidenav {
    --mat-sidenav-scrim-color: theme(colors.black-transparent.50);

    @apply text-inherit;

    .mat-sidenav {
      @apply w-[16.875rem] md:w-[14.375rem] xl:w-[16.875rem] border-none bg-white text-inherit;

      &.mat-drawer-over {
        @apply shadow-sidebar;
      }
    }
  }
}

@mixin enx-nav-list {
  .mat-mdc-nav-list.enx-nav-list {
    --mdc-list-list-item-focus-state-layer-opacity: 0.08;
    --mdc-list-list-item-pressed-state-layer-opacity: 0.08;

    @apply py-0;

    .mdc-list-item {
      @apply px-6 rounded-none md:pl-12;
    }

    &--main {
      @apply flex flex-col h-full;

      .mdc-list-item {
        @apply h-[3.75rem] min-h-[3.75rem] text-sm;

        .mdc-list-item__primary-text {
          @apply text-inherit;
        }

        &.enx-list-item--activated {
          @apply pl-5 border-l-4 border-solid border-green-light bg-silver-light md:pl-11;

          .mdc-list-item_primary-text {
            @apply text-inherit;
          }
        }
      }
    }

    &--secondary {
      .mdc-list-item {
        @apply h-10 min-h-10 text-xs;

        .mdc-list-item__primary-text {
          @apply text-gray;
        }
      }
    }
  }
}

@mixin enx-mat-tab-group {
  .mat-mdc-tab-group.enx-mat-tab-group {
    .mat-mdc-tab-header {
      @apply hidden;
    }
  }
}

@mixin enx-expansion-panel {
  mat-expansion-panel.mat-expansion-panel.enx-expansion-panel {
    @apply bg-inherit rounded-none shadow-none;

    .mat-expansion-panel-header {
      @apply py-[0.563rem] px-4 bg-white rounded-lg;

      .mat-content-hide-toggle {
        @apply m-0;

        .mat-expansion-panel-header-title {
          @apply basis-auto;
        }
        .mat-expansion-panel-header-description {
          @apply justify-end m-0;
        }
      }
    }

    .mat-expansion-panel-content {
      .mat-expansion-panel-body {
        @apply px-4 pt-4 pb-2;
      }
    }
  }
}

@mixin enx-checkbox {
  .mat-mdc-checkbox.enx-checkbox {
    .mdc-form-field {
      .mdc-checkbox {
        @apply basis-auto p-0 m-0 w-6 h-6 bg-white;

        .mat-mdc-checkbox-touch-target {
          @apply w-6 h-6;
        }

        .mdc-checkbox__native-control {
          --mdc-checkbox-state-layer-size: 1.5rem;
        }

        .mdc-checkbox__ripple {
          @apply hidden;
        }
        .mdc-checkbox__background {
          @apply top-0 left-0 w-6 h-6 border border-gray;
          --mdc-checkbox-selected-hover-icon-color: theme(colors.green.DEFAULT);
          --mdc-checkbox-selected-icon-color: theme(colors.green.DEFAULT);
          --mdc-checkbox-selected-focus-icon-color: theme(colors.green.DEFAULT);
          --mdc-checkbox-selected-focus-state-layer-color: theme(colors.green.DEFAULT);
          --mdc-checkbox-selected-hover-state-layer-color: theme(colors.green.DEFAULT);
          --mdc-checkbox-selected-pressed-state-layer-color: theme(colors.green.DEFAULT);
        }
      }
      .mdc-label {
        @apply text-caption-regular text-gray-dark pl-2;
      }
    }
  }
}

@mixin enx-input {
  input.mat-mdc-input-element.enx-input::-webkit-outer-spin-button,
  input.mat-mdc-input-element.enx-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
}

@mixin enx-slider {
  .enx-slider.mdc-slider {
    .mdc-slider__value-indicator {
      @apply bg-green-light-transparent-10 transform-none rounded-lg border-t-0 w-24 h-6;
    }
    span.mdc-slider__value-indicator-text {
      @apply text-body-2-bold text-green text-nowrap mt-1 size-full;
    }
    .mdc-slider__value-indicator::before {
      @apply border-t-0;
    }
    .mat-mdc-slider-visual-thumb .mat-ripple {
      @apply w-0 h-0;
    }
    div.mdc-slider__track--active div.mdc-slider__track--active_fill {
      @apply bg-green;
    }
    div.mdc-slider__track--inactive {
      @apply bg-silver opacity-100;
    }
    div.mdc-slider__thumb-knob {
      @apply bg-green border-0;
    }
  }
}

@mixin enx-progress-bar {
  .mat-mdc-progress-bar {
    @apply h-0.5;
    span.mdc-linear-progress__bar-inner {
      @apply border-t-2;
      border-image: linear-gradient(90deg, #93c11c -28.19%, #93c11c 140.16%) 1;
    }
    div.mdc-linear-progress__buffer-bar {
      @apply bg-silver opacity-100 h-0.5;
    }
  }
}

@mixin enx-tooltip {
  .enx-tooltip.mdc-tooltip {
    .mdc-tooltip__surface {
      @apply max-w-72 bg-white text-caption-regular text-black text-start whitespace-pre-line border border-silver-dark;
    }
  }
}

@mixin enx-info-dialog {
  .enx-info-dialog.mat-mdc-dialog-panel {
    --mat-dialog-container-small-max-width: calc(100vw - 2rem);
    --mat-dialog-container-min-width: min(calc(100vw - 2rem), 18.5rem);

    mat-dialog-container {
      .mat-mdc-dialog-inner-container {
        .mat-mdc-dialog-surface {
          @apply rounded-xl;

          .mat-mdc-dialog-title {
            @apply px-6 pt-9 pb-4 text-body-large-semibold text-black tracking-normal;
          }
          .mat-mdc-dialog-title::before {
            @apply h-0;
          }
          .mat-mdc-dialog-content {
            @apply px-6 pb-6 text-body-2-regular text-gray-dark tracking-normal whitespace-pre-line;
          }
        }
      }
    }
  }
}

@mixin enx-dialog {
  .mat-mdc-dialog-panel.cdk-overlay-pane.enx-dialog {
    mat-dialog-container {
      --mdc-dialog-subhead-tracking: 0;
      --mdc-dialog-supporting-text-tracking: 0;
      --mdc-dialog-subhead-color: theme(colors.black);
      --mdc-dialog-supporting-text-color: theme(colors.black);

      .mat-mdc-dialog-inner-container {
        .mat-mdc-dialog-surface {
          @apply rounded-xl overflow-hidden shadow-green/08;

          .mat-mdc-dialog-component-host {
            @apply flex flex-col overflow-hidden overflow-y-auto;

            .mat-mdc-dialog-content {
              @apply max-h-inherit p-0;
            }

            .mat-mdc-dialog-actions {
              .mat-mdc-button-base + .mat-mdc-button-base {
                margin-left: 0px;
              }
            }
          }
        }
      }
    }
    &--smaller-content {
      mat-dialog-container {
        .mat-mdc-dialog-inner-container {
          .mat-mdc-dialog-surface .mat-mdc-dialog-component-host {
            .mat-mdc-dialog-content {
              @apply max-h-[calc(90vh-4rem)] px-4;
            }
          }
        }
      }
    }
    &--with-tabs {
      mat-dialog-container {
        .mat-mdc-dialog-inner-container {
          .mat-mdc-dialog-surface {
            .mat-mdc-dialog-content {
              @apply overflow-hidden;
            }
          }
        }
      }
    }

    &--direct-flow {
      @apply max-w-[calc(100vw-2rem)];

      mat-dialog-container {
        .mat-mdc-dialog-inner-container {
          .mat-mdc-dialog-surface .mat-mdc-dialog-component-host {
            .mat-mdc-dialog-content {
              @apply max-h-[85vh];
            }
          }
        }
      }
    }

    // not used yet, adjust when needed
    &--padding-sm {
      .mat-mdc-dialog-container .mat-mdc-dialog-inner-container .mat-mdc-dialog-surface .mat-mdc-dialog-component-host {
        .mat-mdc-dialog-title {
          @apply px-4 pb-4;
        }

        .mat-mdc-dialog-content {
          @apply py-0 px-4;
        }
      }
    }

    // not used yet, adjust when needed
    &--padding-md {
      .mat-mdc-dialog-container .mat-mdc-dialog-inner-container .mat-mdc-dialog-surface .mat-mdc-dialog-component-host {
        .mat-mdc-dialog-title {
          @apply px-6 pb-6;
        }

        .mat-mdc-dialog-content {
          @apply py-0 px-6;
        }
      }
    }

    &--padding-lg {
      .mat-mdc-dialog-container .mat-mdc-dialog-inner-container .mat-mdc-dialog-surface .mat-mdc-dialog-component-host {
        .mat-mdc-dialog-title {
          @apply pb-6 px-4 md:px-8;
        }

        .mat-mdc-dialog-content {
          @apply py-0 px-4 md:px-8;
        }

        .mat-mdc-dialog-actions {
          @apply pt-8 pb-10 px-4 md:px-8 md:pb-8;
        }
      }
    }
  }
}

@mixin enx-reward-dialog {
  @include enx-info-dialog;
  .enx-reward-dialog.mat-mdc-dialog-panel.cdk-overlay-pane {
    mat-dialog-container {
      .mat-mdc-dialog-inner-container {
        .mat-mdc-dialog-surface .mat-mdc-dialog-component-host {
          .mat-mdc-dialog-title {
            @apply text-h4-semibold text-green-light tracking-normal;
          }

          .mat-mdc-dialog-content {
            @apply px-4 py-4 md:px-8;
          }
        }
      }
    }
  }
}

@mixin enx-quiz-dialog {
  .enx-quiz-dialog.mat-mdc-dialog-panel {
    --mat-dialog-container-max-width: calc(100vw - 2rem);
    --mat-dialog-container-small-max-width: calc(100vw - 2rem);

    mat-dialog-container {
      .mat-mdc-dialog-inner-container {
        .mat-mdc-dialog-surface {
          @apply bg-green-0 rounded-xl;

          .mat-mdc-dialog-component-host {
            @apply max-h-inherit;

            .mat-mdc-dialog-title {
              @apply font-inherit tracking-normal;
            }

            .mat-mdc-dialog-content {
              @apply -mt-1 px-0 pb-0 bg-white font-inherit tracking-normal;

              .enx-quiz-title {
                @apply w-full px-2 pt-6 pb-8 bg-green-0 text-h4-semibold flex justify-center;
              }
            }

            .mat-mdc-dialog-actions {
              @apply pt-7 bg-white font-inherit tracking-normal;
            }
          }
        }
      }
    }
  }
}

@mixin enx-pv-download-dialog {
  .enx-pv-download-dialog.mat-mdc-dialog-panel {
    --mat-dialog-container-max-width: calc(100vw - 2rem);
    --mat-dialog-container-small-max-width: calc(100vw - 2rem);

    mat-dialog-container {
      .mat-mdc-dialog-inner-container {
        .mat-mdc-dialog-surface {
          @apply rounded-xl;

          .mat-mdc-dialog-component-host {
            @apply max-h-inherit;

            .mat-mdc-dialog-content {
              @apply px-4 py-0 font-inherit tracking-normal;
            }
          }
        }
      }
    }
  }
}

@mixin enx-snackbar {
  .mdc-snackbar.enx-snackbar {
    --mdc-snackbar-container-color: theme(colors.white);
    --mdc-snackbar-container-shape: 0.5rem;

    @apply mx-4 my-2.5 md:mx-5 md:my-1.5;

    .mdc-snackbar__surface {
      @apply min-w-52 pr-2.5 shadow-snackbar;

      .mdc-snackbar__label {
        @apply py-2.5 pr-0 pl-3;

        &::before {
          @apply absolute top-1/2 -translate-y-1/2 w-6 h-6;
        }
      }
    }

    &--success {
      .mdc-snackbar__label {
        @apply text-green-light;

        &::before {
          content: url('/assets/icons/success.svg');
        }
      }
    }

    &--error {
      .mdc-snackbar__label {
        @apply text-red;

        &::before {
          content: url('/assets/icons/error.svg');
        }
      }
    }

    &--warning {
      .mdc-snackbar__label {
        @apply text-orange;

        &::before {
          content: url('/assets/icons/warning.svg');
        }
      }
    }
  }
}

@mixin enx-loader-dialog {
  .enx-loader-dialog {
    @apply fixed inset-0 flex justify-center items-center;
    @apply bg-transparent;

    .mat-mdc-dialog-surface {
      box-shadow: none;
      overflow: hidden;
      background-color: transparent;
    }
  }
}

@mixin enx-backdrop {
  .cdk-overlay-backdrop.enx-backdrop {
    &--loader {
      @apply fixed inset-0 bg-black bg-opacity-65;
    }

    &--black-15 {
      @apply bg-black bg-opacity-15;
    }
  }
}

@mixin enx-select {
  .enx-select {
    .mat-mdc-select-placeholder {
      @apply text-gray-dark;
    }

    &--arrow-hidden {
      .mat-mdc-select-arrow {
        @apply hidden;
      }
    }
  }
}

@mixin enx-calendar {
  .mat-calendar.enx-calendar {
    .mat-calendar-header .mat-calendar-controls {
      @apply justify-between;

      .mat-calendar-previous-button {
        @apply order-1;
      }

      .mat-calendar-period-button {
        --mat-datepicker-calendar-period-button-text-color: theme(colors.black);
        @apply order-2 text-body-regular;
      }

      .mat-calendar-next-button {
        --mdc-icon-button-disabled-icon-color: theme(colors.gray-light);
        @apply order-3;
      }

      .mat-calendar-spacer {
        @apply hidden;
      }
    }

    .mat-calendar-content .mat-calendar-table {
      .mat-calendar-table-header {
        --mat-datepicker-calendar-header-text-color: theme(colors.gray-dark);
        --mat-datepicker-calendar-header-divider-color: transparent;

        @apply text-caption-regular;
      }

      .mat-calendar-body {
        .mat-calendar-body-cell {
          --mat-datepicker-calendar-date-text-color: theme(colors.gray-dark);
          --mat-datepicker-calendar-date-disabled-state-text-color: theme(colors.gray-light);
          --mat-datepicker-calendar-date-today-outline-color: transparent;
          --mat-datepicker-calendar-date-today-disabled-state-outline-color: transparent;
          --mat-datepicker-calendar-date-today-selected-state-outline-color: transparent;

          @apply text-body-regular;

          .mat-calendar-body-cell-content {
            @apply rounded-lg;
          }
        }

        .mat-calendar-body-label {
          &[colspan='7'] {
            @apply hidden;
          }

          &:not([colspan='7']) {
            @apply invisible;
          }
        }
      }
    }
  }
}
